/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@allthingswww/client-act-shared';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { transformSpecialitiesStringToID } from '../../functions/utils/occupations';
import Layout from '../components/Layout';
import {
  getMembers, fetchMembers, getAllSpecialities, updateMembers,
} from '../redux/members';
import Survey from '../components/Survey';
import surveyData from '../../static/surveys/businessProfileAdmin.json';
import surveyDataReadOnly from '../../static/surveys/businessProfileReadOnly.json';
import expandMemberData from '../lib/expandMemberData';
import compressData from '../lib/compressData';
import { getMyInfo, fetchMyInfo } from '../redux/member';
import { transformSpecialityIntoLabel, transformSpecialities } from '../lib/companySpecialities';

const MainContainer = styled.div``;
class SurveyjsClassWrapper extends React.Component {
  render() {
    const {
      isAdmin, isSuperAdmin, surveyData, surveyDataReadOnly, onSubmit, expandedMemberData,
    } = this.props;
    return (
      <Survey
        model={isAdmin || isSuperAdmin ? surveyData : surveyDataReadOnly}
        onSubmit={onSubmit}
        data={expandedMemberData}
      />
    );
  }
}

const MembersPage = (props) => {
  const componentRef = useRef();
  const id = props['*'];
  const dispatch = useDispatch();
  const members = useSelector(getMembers);
  const allSpecialities = useSelector(getAllSpecialities);
  const currentMember = useSelector(getMyInfo);
  const isAdmin = currentMember?.role?.isAdmin;
  const isSuperAdmin = currentMember?.role?.isSuperAdmin;
  const member = members.filter((m) => m._id === id)[0];

  const formattedMember = {
    ...member,
    companySpecialities: transformSpecialityIntoLabel(member),
  };

  const onSubmit = (surveyResults) => {
    window.scrollTo(0, 0);
    const data = compressData(surveyResults.data);
    let finalCompanySpecialities = '';
    data.companySpecialities.forEach((speciality) => {
      finalCompanySpecialities += `${transformSpecialitiesStringToID(
        speciality,
      )},`;
    });

    if (data.otherSpecialities) {
      data.otherSpecialities.split(',').forEach((speciality) => {
        if (speciality !== '') {
          finalCompanySpecialities += `${transformSpecialitiesStringToID(
            speciality,
          )},`;
        }
      });
    }

    data.companySpecialities = finalCompanySpecialities;
    delete data.otherSpecialities;
    dispatch(updateMembers({ data, id, action: 'memberEdit' }));
    setTimeout(() => {
      navigate('/members');
      refresh();
    }, 2000);
  };

  const refresh = () => {
    dispatch(fetchMembers());
    dispatch(fetchMyInfo());
  };

  const expandedMemberData = expandMemberData(formattedMember);
  const cleanedUpSpecialities = allSpecialities.filter((sp) => sp.label !== '');

  const labels = cleanedUpSpecialities.map((speciality) => transformSpecialities(speciality.label));
  surveyData.pages[0].elements[4].choices = labels;

  return (
    <Layout title="Member Edit" subtitle="">
      <MainContainer>
        <SurveyjsClassWrapper
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          surveyData={surveyData}
          surveyDataReadOnly={surveyDataReadOnly}
          onSubmit={onSubmit}
          expandedMemberData={expandedMemberData}
          ref={componentRef}
        />
      </MainContainer>
      <ReactToPrint
        trigger={() => <Button.Button variant>Print</Button.Button>}
        content={() => componentRef.current}
        pageStyle="@page{ margin: 2.5cm; }"
      />
    </Layout>
  );
};

export default MembersPage;
