import { transformContractorData } from '../../functions/utils/occupations';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function transformSpecialities(speciality) {
  return speciality.split('_').map((sp) => capitalizeFirstLetter(sp)).join(' ');
}

// eslint-disable-next-line import/prefer-default-export
export const transformSpecialityIntoLabel = (member) => transformContractorData([member])
  .filter((sp) => sp.label !== '')
  .map((sp) => transformSpecialities(sp.label));
