import _ from 'lodash';

const compressData = (resultData) => {
  const {
    role,
    memberStatus,
    organization,
    companyPoBox,
    companyInfo,
    nameOfSponsor,
    expirationDate,
    nameOfRepresentative,
    companyWebsite,
    email,
    membershipPayment,
    companyZip,
    companyPhone,
    memberAddress1,
    stripeID,
    companyAddress2,
    fullName,
    memberAddress2,
    otherSpecialities,
    companySpecialities,
    principaTypeOfBusiness,
    companyState,
    companyAddress1,
    memberZip,
    companyDescription,
    memberState,
    netlifyID,
    allowMemberContactEmails,
    fax,
    companyName,
    heardFrom,
    companyCity,
    notes,
    emergency,
    membershipLevel,
    yearsInBusiness,
    companyEmail,
    nameOfSeniorOfficerOrLocalManager,
    memberCity,
    adminReviewed,
    alternateRepresentative,
    transactionEmails,
    phone,
    viewCount,
    ...data
  } = resultData;
  return _.omitBy({
    memberStatus,
    organization,
    companyPoBox,
    companyInfo,
    nameOfSponsor,
    expirationDate,
    nameOfRepresentative,
    companyWebsite,
    email,
    membershipPayment,
    companyZip,
    companyPhone,
    memberAddress1,
    stripeID,
    companyAddress2,
    fullName,
    memberAddress2,
    otherSpecialities,
    companySpecialities,
    principaTypeOfBusiness,
    companyState,
    companyAddress1,
    memberZip,
    companyDescription,
    memberState,
    netlifyID,
    allowMemberContactEmails,
    fax,
    companyName,
    heardFrom,
    companyCity,
    notes,
    emergency,
    membershipLevel,
    yearsInBusiness,
    companyEmail,
    nameOfSeniorOfficerOrLocalManager,
    memberCity,
    adminReviewed,
    alternateRepresentative,
    transactionEmails,
    phone,
    role,
    viewCount,
    data: JSON.stringify(data),
  }, _.isUndefined);
};

export default compressData;
